import React from 'react';
import './index.css';
import cx from 'classnames';
import {FormattedMessage} from "react-intl";

export default ({locale}) => {
    const classes = cx({
        'how-much-food__potato': true,
        'how-much-food__potato--ru': locale === 'ru'
    });
    
    return (
        <div className="how-much-food">
            <div className="how-much-food__header">
                <FormattedMessage id="how.much.food.header" />
            </div>
            <div className="how-much-food__images">
                <img className="how-much-food__img--lg" src="/static/images/how-much-food-lg.png" alt="" />
                <img className="how-much-food__img--md" src="/static/images/how-much-food-md.png" alt="" />
                <img className="how-much-food__img--sm" src="/static/images/how-much-food-sm.png" alt="" />
                <div className="how-much-food__items">
                    <div className="how-much-food__cheese">
                        <FormattedMessage id="how.much.food.cheese" /><span className="how-much-food--emphasis"><FormattedMessage id="how.much.food.cheese.amt" /></span>
                    </div>
                    <div className="how-much-food__eel">
                        <FormattedMessage id="how.much.food.eel" /><span className="how-much-food--emphasis"><FormattedMessage id="how.much.food.eel.amt" /></span>
                    </div>
                    <div className="how-much-food__almond">
                        <FormattedMessage id="how.much.food.almond" /><span className="how-much-food--emphasis"><FormattedMessage id="how.much.food.almond.amt" /></span>
                    </div>
                    <div className="how-much-food__yogurt">
                        <FormattedMessage id="how.much.food.yogurt" /><span className="how-much-food--emphasis"><FormattedMessage id="how.much.food.yogurt.amt" /></span>
                    </div>
                    <div className="how-much-food__milk">
                        <FormattedMessage id="how.much.food.milk" /><span className="how-much-food--emphasis"><FormattedMessage id="how.much.food.milk.amt" /></span>
                    </div>
                    <div className={classes}>
                        <FormattedMessage id="how.much.food.potato" /><span className="how-much-food--emphasis"><FormattedMessage id="how.much.food.potato.amt" /></span>
                    </div>
                    <div className="how-much-food__pasta">
                        <FormattedMessage id="how.much.food.pasta" /><span className="how-much-food--emphasis"><FormattedMessage id="how.much.food.pasta.amt" /></span>
                    </div>
                </div>
            </div>
        </div>
    );
}
