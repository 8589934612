import React from 'react';
import Item from "./Item";
import BuyNowButton from '../../common/buy-now-button'
import './index.css';
import cx from 'classnames';
import {FormattedMessage, injectIntl} from "react-intl";

export default injectIntl(({intl, locale}) => {
    const classes_md = cx({
        'which-cal-content__img--md': true,
        'which-cal-content__img--md--ru': locale === 'ru'
    });

    const classes_sm = cx({
        'which-cal-content__img--sm': true,
        'which-cal-content__img--sm--ru': locale === 'ru'
    });

    const classes_btn = cx({
        'which-cal-buy-now__button': true,
        'which-cal-buy-now__button--ru': locale === 'ru'
    });
    
    return (
        <div className="which-cal">
            <div className="which-cal-content">
                <div className="which-cal-content-items">
                    <Item
                        middle
                        middle__header
                        header={<span dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'which.cal.vitamin.header'})}}/>}
                    >
                        <div 
                            className="which-cal-content-item__text"
                            dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'which.cal.vitamin.text'})}}
                        />
                    </Item>
                    <Item
                        left
                        left__header
                        header={<FormattedMessage id="which.cal.citrate.header" />}
                    >
                        <div className="which-cal-content-item__text">
                            <FormattedMessage id="which.cal.citrate.text" />
                        </div>
                    </Item>
                    <Item
                        right
                        right__header
                        header={<FormattedMessage id="which.cal.500.header" />}
                    >
                        <div className="which-cal-content-item__text">
                            <FormattedMessage id="which.cal.500.text" />
                        </div>
                    </Item>
                </div>
                <img className="which-cal-content__img--lg" src="/static/images/which-cal-arrow--lg.png" alt="" />
                <img className={classes_md} src="/static/images/which-cal-arrow--md.png" alt="" />
                <img className={classes_sm} src="/static/images/which-cal-arrow--sm.png" alt="" />
                <div className="which-cal__header">
                    <FormattedMessage id="which.cal.header" />
                </div>
                <div className="which-cal-content-citrate">
                    <div className="which-cal-content-citrate__header">
                        <FormattedMessage id="which.cal.info.header" />
                    </div>
                    <img className="which-cal-content-citrate__img" src="/static/svg/which-cal-content-citrate-exclamation.svg" alt="" />
                    <div className="which-cal-content-citrate__content">
                        <div className="which-cal-content-citrate__content__item">
                            <img
                                src="/static/svg/which-cal-content-citrate-point.svg"
                            />
                            <div className="which-cal-content-citrate__content__text">
                                <FormattedMessage id="which.cal.info.first.point" />
                            </div>
                        </div>
                        <div className="which-cal-content-citrate__content__item">
                            <img
                                src="/static/svg/which-cal-content-citrate-point.svg"
                            />
                            <div className="which-cal-content-citrate__content__text">
                                <FormattedMessage id="which.cal.info.second.point" />
                            </div>
                        </div>
                        <div className="which-cal-content-citrate__content__item">
                            <img
                                src="/static/svg/which-cal-content-citrate-point.svg"
                            />
                            <div 
                                className="which-cal-content-citrate__content__text"
                                dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'which.cal.info.third.point'})}}
                            />
                        </div>
                        <div className="which-cal-content-citrate__content__item">
                            <img
                                src="/static/svg/which-cal-content-citrate-point.svg"
                            />
                            <div className="which-cal-content-citrate__content__text">
                                <FormattedMessage id="which.cal.info.fourth.point" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="which-cal-buy-now">
                    <img
                        className="which-cal-buy-now__bottle"
                        src="/static/images/middle-buy-now-bottle.png"
                    />
                    <span className={classes_btn}>
                        <BuyNowButton />
                    </span>
                </div>
            </div>
        </div>
    );
})
