import React from 'react';
import HeaderText from "./HeaderText";
import Item from "./Item";
import './index.css';
import {FormattedMessage, injectIntl} from "react-intl";

export default injectIntl(({intl}) => (
    <div className="protect-bones">
        <HeaderText/>
        <div className="protect-bones-items">
            <Item
                logo="/static/svg/protect-bones-bullseye.svg"
                header={<FormattedMessage id="protect.bones.item.left.header" />}
            >
                <div className="protect-bones-item-left-text">                
                    <FormattedMessage id="protect.bones.item.left.text" />
                </div>
            </Item>
            <Item
                logo="/static/svg/protect-bones-knee.svg"
                header={<FormattedMessage id="protect.bones.item.center.header" />}
            >
                <div 
                    className="protect-bones-item-center-text"
                    dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'protect.bones.item.center.text'})}}
                />
            </Item>
            <Item
                logo="/static/svg/protect-bones-clock.svg"
                header={<FormattedMessage id="protect.bones.item.right.header" />}
            >
                <div className="protect-bones-item-right-text">
                    <FormattedMessage id="protect.bones.item.right.text" />
                </div>
            </Item>
        </div>
    </div>
));