import React, {Component} from 'react';
import {IntlProvider} from 'react-intl';
import Header from './components/sections/header'
import TopBanner from './components/sections/top-banner';
import ProtectBones from './components/sections/protect-bones';
import Osteoporosis from './components/sections/osteoporosis';
import BoneHealth from './components/sections/bone-health';
import Calcium from './components/sections/calcium';
import Menopause from './components/sections/menopause';
import Signs from './components/sections/signs';
import MiddleBuyNow from './components/sections/middle-buy-now';
import HowMuch from './components/sections/how-much';
import IncreasedNeed from './components/sections/increased-need';
import HowMuchFood from './components/sections/how-much-food';
import WhichCal from './components/sections/which-cal';
import KeepHealthy from './components/sections/keep-healthy';
import FAQ from './components/sections/faq';
import Specialists from './components/sections/specialists';
import Share from './components/sections/share';
import Footer from './components/sections/footer';
import messages from "./locales/messages";

class Page extends Component {
    render() {
        const { locale } = this.props;
        return (
            <IntlProvider onError={() => {}} locale={locale} messages={messages[locale]}>
                <div className="page">
                    <Header locale={locale} />
                    <TopBanner locale={locale} />
                    <ProtectBones/>
                    <Osteoporosis/>
                    <BoneHealth locale={locale} />
                    <Calcium/>
                    <Menopause locale={locale} />
                    <Signs/>
                    <MiddleBuyNow/>
                    <HowMuch locale={locale} />
                    <IncreasedNeed/>
                    <HowMuchFood locale={locale} />
                    <WhichCal locale={locale} />
                    <KeepHealthy/>
                    <FAQ/>
                    {/* <Specialists/> */}
                    <Share/>
                    <Footer locale={locale} />
                </div>
            </IntlProvider>
        );
    }
}
    

export default Page;
