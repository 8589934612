import React from 'react';
import './index.css';
import cx from 'classnames';
import {FormattedMessage, injectIntl} from "react-intl";

export default injectIntl(({intl, locale}) => {
    const classes = cx({
        'menopause-content-graph__text': true,
        'menopause--murrurisk': true,
        'menopause--murrurisk--ru': locale === 'ru'
    });

    const classes_age = cx({
        'menopause-content-graph__text': true,
        'menopause--vanus': true,
        'menopause--vanus--ru': locale === 'ru'
    });

    return (
        <div className="menopause">
            <div className="menopause-header__text">
                <FormattedMessage id="menopause.header" />
            </div>
            <div className="menopause-content">
                <div className="menopause-content__image">
                    <div className="menopause-content__image--content">
                        <img src="/static/svg/menopause-graph.svg" alt="" />
                        <div className="menopause-content-graph__text menopause--luumass">
                            <FormattedMessage id="menopause.graph.1" />
                        </div>
                        <div className="menopause-content-graph__text menopause--tippluumass">
                            <FormattedMessage id="menopause.graph.2" />
                        </div>
                        <div className="menopause-content-graph__text menopause--mehed">
                            <FormattedMessage id="menopause.graph.3" />
                        </div>
                        <div className="menopause-content-graph__text menopause--naised">
                            <FormattedMessage id="menopause.graph.4" />
                        </div>
                        <div 
                            className={classes}
                            dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'menopause.graph.5'})}}
                        />
                        <div className="menopause-content-graph__text menopause--menopaus">
                            <FormattedMessage id="menopause.graph.6" />
                        </div>
                        <div className={classes_age}>
                            <FormattedMessage id="menopause.graph.7" />
                        </div>
                    </div>
                </div>
                <div className="menopause-content__item">
                    <img
                        src="/static/svg/menopause-point.svg"
                    />
                    <div className="menopause-content__text">
                        <FormattedMessage id="menopause.first.point" />
                    </div>
                </div>
                <div className="menopause-content__item">
                    <img
                        src="/static/svg/menopause-point.svg"
                    />
                    <div 
                        className="menopause-content__text"
                        dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'menopause.second.point'})}}
                    />
                </div>
                <div className="menopause-content__item">
                    <img
                        src="/static/svg/menopause-point.svg"
                    />
                    <div 
                        className="menopause-content__text"
                        dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'menopause.third.point'})}}
                    />
                </div>
            </div>
        </div>
    );
})
