import React from 'react';
import './index.css';
import cx from 'classnames';
import {injectIntl} from "react-intl";

export default injectIntl(({intl, locale}) => {
    const classes = cx({
        'bone-health__image-text': true,
        'bone-health__image-text--ru': locale === 'ru'
    });

    const classes_item = cx({
        'bone-health-content__item': true,
        'bone-health-content__item--ru': locale === 'ru'
    });

    return (
        <div className="bone-health" id="bone-health">
            <div 
                className="bone-health-header__text"
                dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'bone.health.header'})}}
                />
            <div className="bone-health__wrap">
                <div className="bone-health-content">
                    <div className={classes_item}>
                        <img
                            src="/static/svg/bone-health-point.svg"
                        />
                        <div 
                            className="bone-health-content__text"
                            dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'bone.health.first.point'})}}
                        />
                    </div>
                    <div className={classes_item}>
                        <img
                            src="/static/svg/bone-health-point.svg"
                        />
                        <div 
                            className="bone-health-content__text"
                            dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'bone.health.second.point'})}}
                        />
                    </div>
                    <div className={classes_item}>
                        <img
                            src="/static/svg/bone-health-point.svg"
                        />
                        <div 
                            className="bone-health-content__text"
                            dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'bone.health.third.point'})}}
                        />
                    </div>
                </div>
            </div>
            <div className="bone-health__image">
                <div className="bone-health__image--sm">
                    <img alt="" src="/static/images/bone-health-bg.png" />
                </div>
                <div className="bone-health__image--arrow">
                <a href="http://www.vitamiinD.ee" target="_blank"><img alt="" src="/static/svg/bone-health-arrow.svg" /></a>
                </div>
                <div className={classes}>
                <a 
                    href="http://www.vitamiinD.ee" 
                    target="_blank"
                    dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'bone.health.arrow'})}}
                />
                </div>
            </div>
        </div>
    );
})
