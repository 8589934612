import React from 'react';
import cx from 'classnames';
import './Item.css';

export default ({header, children, left, middle, right, left__header, middle__header, right__header}) => {
    const classes = cx({
        'which-cal-item--left': left,
        'which-cal-item--middle': middle,
        'which-cal-item--right': right
    });
    const classes__header = cx({
        'which-cal-item--left__header': left__header,
        'which-cal-item--middle__header': middle__header,
        'which-cal-item--right__header': right__header
    });
    return(
        <div className={["which-cal-item", classes].join(" ")}>
            <div className={classes__header}>
                {header}
            </div>
            <div>
                {children}
            </div>
        </div>
    );
}