import React from 'react';
import './HeaderText.css';
import {FormattedMessage} from "react-intl";

export default () => (
    <div className="keep-healthy-header-text">
        <FormattedMessage id="keep.healthy.header" />
    </div>
);

