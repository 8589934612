import React from 'react';
import './index.css';
import {FormattedMessage, injectIntl} from "react-intl";

export default injectIntl(({intl}) => (
    <div className="faq">
        <div className="faq-header__text">
            <FormattedMessage id="faq.header" />
        </div>
        <img className="faq-header__image" alt="" src="/static/images/faq-banner.png" />
        <div className="faq-content">
            <div className="faq-q">
                <FormattedMessage id="faq.q.1" />
            </div>
            <div 
                className="faq-a"
                dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'faq.a.1'})}}
            />
            <div className="faq-q">
                <FormattedMessage id="faq.q.2" />
            </div>
            <div className="faq-a">
                <FormattedMessage id="faq.a.2" />
            </div>
            <div 
                className="faq-q"
                dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'faq.q.3'})}}
            />
            <div 
                className="faq-a"
                dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'faq.a.3'})}}
            />
                
            <div className="faq-q">
                <FormattedMessage id="faq.q.4" />
            </div>
            <div className="faq-a">
                <FormattedMessage id="faq.a.4" />
            </div>
        </div>
    </div>
));
