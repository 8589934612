import React from 'react';
import './index.css';
import cx from 'classnames';
import {FormattedMessage} from "react-intl";

export default ({locale}) => {
    const classes = cx({
        'how-much-needs__content': true,
        'how-much-needs__content--ru': locale === 'ru'
    });

    const classes_head = cx({
        'how-much-interest__header': true,
        'how-much-interest__header--ru': locale === 'ru'
    });
    
    return (
        <div className="how-much">
            <div className="how-much__header">
                <FormattedMessage id="how.much.header" />
            </div>
            <div className="how-much__items">
                <div className="how-much-needs">
                    <div className={classes}>
                        <div className="how-much-needs__table">
                            <table className="how-much-needs__table-text">
                                <tbody>
                                    <tr>
                                        <td><FormattedMessage id="how.much.table.first.text" /></td>
                                        <td><FormattedMessage id="how.much.table.first.figures" /></td>
                                    </tr>
                                    <tr>
                                        <td><FormattedMessage id="how.much.table.second.text" /></td>
                                        <td><FormattedMessage id="how.much.table.second.figures" /></td>
                                    </tr>
                                    <tr>
                                        <td><FormattedMessage id="how.much.table.third.text" /></td>
                                        <td><FormattedMessage id="how.much.table.third.figures" /></td>
                                    </tr>
                                    <tr>
                                        <td><FormattedMessage id="how.much.table.fourth.text" /></td>
                                        <td><FormattedMessage id="how.much.table.fourth.figures" /></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="how-much-needs__footer">
                            <FormattedMessage id="how.much.needs.footer" />
                        </div>
                    </div>
                    <img className="how-much-needs__img" src="/static/svg/how-much-needs-cycle.svg" alt="" />
                </div>
                <div className="how-much-interest">
                    <img className="how-much-interest__img" src="/static/svg/how-much-interest-lightbulb.svg" alt="" />
                    <div className={classes_head}>
                        <FormattedMessage id="how.much.interest.header" />
                    </div>
                    <div className="how-much-interest-content">
                        <div className="how-much-interest-content__item">
                            <img
                                src="/static/svg/how-much-interest-point.svg"
                            />
                            <div className="how-much-interest-content__text">
                                <FormattedMessage id="how.much.interest.text.1" />
                            </div>
                        </div>
                        <div className="how-much-interest-content__item">
                            <img
                                src="/static/svg/how-much-interest-point.svg"
                            />
                            <div className="how-much-interest-content__text">
                                <FormattedMessage id="how.much.interest.text.2" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
