import React from 'react';
import cx from 'classnames';
import './Item.css';

export default ({logo, text}) => {
    const classes = cx({
        'increased-need-item': true
    });
    return (
        <div className={classes}>
            <img
                src={logo}
            />
            <div className="increased-need-item__text">
                {text}
            </div>
        </div>
    );
}