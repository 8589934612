import React from 'react';
import './index.css';
import {FormattedMessage, injectIntl} from "react-intl";

export default injectIntl(({intl}) => (
    <div className="calcium">
        <div className="calcium-header__text">
            <FormattedMessage id="calcium.header" />
        </div>
        <img className="calcium-header__image" alt="" src="/static/images/calcium-banner.png" />
        <div className="calcium-content">
            <div className="calcium-content__item">
                <img
                    src="/static/svg/calcium-point.svg"
                />
                <div 
                    className="calcium-content__text"
                    dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'calcium.first.point'})}}
                />
            </div>
            <div className="calcium-content__item">
                <img
                    src="/static/svg/calcium-point.svg"
                />
                <div className="calcium-content__text">
                    <FormattedMessage id="calcium.second.point" />
                </div>
            </div>
            <div className="calcium-content__item">
                <img
                    src="/static/svg/calcium-point.svg"
                />
                <div className="calcium-content__text">
                    <FormattedMessage id="calcium.third.point" />
                </div>
            </div>
        </div>
    </div>
));
