import React from 'react';
import './index.css';
import {FormattedMessage, injectIntl} from "react-intl";

export default injectIntl(({intl}) => (
    <div className="osteoporosis">
        <div className="osteoporosis-header__text">
            <FormattedMessage id="osteoporosis.header" />
        </div>
        <div className="osteoporosis__wrap">
            <div className="osteoporosis-content">
                <div className="osteoporosis-content__item">
                    <img
                        src="/static/svg/osteoporosis-point.svg"
                    />
                    <div className="osteoporosis-content__text">
                        <FormattedMessage id="osteoporosis.first.point" />
                    </div>
                </div>
                <div className="osteoporosis-content__item">
                    <img
                        src="/static/svg/osteoporosis-point.svg"
                    />
                    <div className="osteoporosis-content__text">
                        <FormattedMessage id="osteoporosis.second.point" />
                    </div>
                </div>
                <div className="osteoporosis-content__item">
                    <img
                        src="/static/svg/osteoporosis-point.svg"
                    />
                    <div 
                        className="osteoporosis-content__text"
                        dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'osteoporosis.third.point'})}}
                    />
                </div>
            </div>
            <div 
                className="osteoporosis-footer__text"            
                dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'osteoporosis.footer'})}}
            />
        </div>
        <div className="osteoporosis__image">
            <img alt="" src="/static/images/osteoporosis.png" />
        </div>
    </div>
));
