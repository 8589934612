import React from 'react';
import HeaderText from "./HeaderText";
import Item from "./Item";
import './index.css';
import {FormattedMessage, injectIntl} from "react-intl";

export default injectIntl(({intl}) => (
    <div className="keep-healthy">
        <HeaderText/>
        <div className="keep-healthy-items">
            <div className="keep-healthy-items--useful">
                <div className="keep-healthy-items--useful__header">
                    <FormattedMessage id="keep.healthy.good.header" />
                </div>
                <Item
                    large
                    logo="/static/svg/keep-healthy-milk.svg"
                    text ={<span dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'keep.healthy.items.milk'})}}/>}
                >  
                </Item>
                <Item
                    large
                    logo="/static/svg/keep-healthy-active.svg"
                    text={<span dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'keep.healthy.items.active'})}}/>}
                >     
                </Item>
            </div>
            <div className="keep-healthy-items--danger">
                <div className="keep-healthy-items--danger__header">
                    <FormattedMessage id="keep.healthy.danger.header" />
                </div>
                <Item
                    logo="/static/svg/keep-healthy-smoking.svg"
                    text ={<FormattedMessage id="keep.healthy.items.smoking" />}
                >     
                </Item>
                <Item
                    logo="/static/svg/keep-healthy-soda.svg"
                    text={<FormattedMessage id="keep.healthy.items.soda" />}
                >     
                </Item>
                <Item
                    logo="/static/svg/keep-healthy-salt.svg"
                    text={<FormattedMessage id="keep.healthy.items.salt" />}
                >   
                </Item>
                <Item
                    logo="/static/svg/keep-healthy-coffee.svg"
                    text={<FormattedMessage id="keep.healthy.items.coffee" />}
                >    
                </Item>
                <Item
                    logo="/static/svg/keep-healthy-alcohol.svg"
                    text={<FormattedMessage id="keep.healthy.items.alcohol" />}
                >    
                </Item>
            </div>
        </div>
    </div>
));