import React from 'react';
import './index.css';
import cx from 'classnames';
import {FormattedMessage} from "react-intl";

export default ({locale}) => {
    const classes = cx({
        'top-banner__text': true,
        'top-banner__text--ru': locale === 'ru'
    });

    const classes_gr = cx({
        'top-banner__bottom-gradient': true,
        'top-banner__bottom-gradient--ru': locale === 'ru'
    });

    const classes_btl = cx({
        'top-banner__image-bottle': true,
        'top-banner__image-bottle--ru': locale === 'ru'
    });

    return (
        <div className="top-banner">
            <img
                src="/static/images/top-banner.png"
                alt=""
                className="top-banner__image"
            />
            <img
                src="/static/images/top-banner--mobile.png"
                alt=""
                className="top-banner__image--mobile"
            />
            <img
                src="/static/images/375.png"
                alt=""
                className="top-banner__image--mobile375"
            />
            <img
                src="/static/svg/banner-title.svg"
                alt=""
                className="top-banner__logo"
            />
            <div
                className={classes_gr}
            >
                <div className={classes}>
                    <FormattedMessage id="top.banner.text" />
                </div>
                <img
                src="/static/images/top-banner-bottle.png"
                alt=""
                className={classes_btl}
            />
            </div>
            
        </div>
    );
}
