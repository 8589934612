import React from 'react';
import cx from 'classnames';
import './Item.css';

export default ({url, header, children, large, phantom, bottom}) => {
        const classes = cx({
                'signs-item': true,
                'signs-item--large': large,
                'signs-item--phantom': phantom,
                'signs-item--bottom': bottom
        });
        return (
            <div className={classes}>
                    <img
                        className="signs-item__img"
                        src={url}
                    />
                    <div className="signs-item__header">
                            {header}
                    </div>
                    <div className="signs-item__text">
                            {children}
                    </div>
            </div>
        );
}
