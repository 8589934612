import React from 'react';
import './HeaderText.css';
import {FormattedMessage} from "react-intl";

export default () => (
    <div className="increased-need-header-text">
        <FormattedMessage id="increased.need.header" />
    </div>
);

