import React from 'react';
import cx from 'classnames';
import './Item.css';

export default ({large, logo, text, children}) => {
    const classes = cx({
        'keep-healthy-item': true,
        'keep-healthy-item--large': large
    });
    return (
        <div className={classes}>
            <img
                src={logo}
            />
            <div className="keep-healthy-item__text">
                {text} {children}
            </div>
        </div>
    );
}