import React from 'react';
import Item from './Item';
import './index.css';
import {FormattedMessage, injectIntl} from "react-intl";

export default injectIntl(({intl}) => (
    <div className="signs" id="signs">
        <div className="signs__header">
            <FormattedMessage id="signs.header" />
        </div>
        <div className="signs__items">
            <div className="signs__items--left">
                <Item
                    url="/static/images/signs-milk.png"
                >
                    <div>
                        <FormattedMessage id="signs.milk" />
                    </div>
                </Item>
                <Item
                    url="/static/images/signs-sofa.png"
                >
                    <div
                        dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'signs.sofa'})}}
                    />
                </Item>
            </div>
            <div className="signs__items--middle">
                <Item
                    url="/static/images/signs-age.png"
                    >
                    <div
                        dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'signs.age'})}}
                    />
                </Item>
                <Item
                    small
                    url="/static/images/signs-cast.png"
                    >
                    <div
                        dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'signs.cast'})}}
                    />
                </Item>
                <Item
                    large
                    url="/static/images/signs-smile.png"
                    >
                </Item>
                <Item
                    bottom
                    url="/static/images/signs-smoke.png"
                    >
                    <div>
                        <FormattedMessage id="signs.smoke" />
                    </div>
                </Item>
            </div>
            <div className="signs__items--right">
                <Item
                    url="/static/images/signs-broken.png"
                >
                    <div>
                        <FormattedMessage id="signs.broken" />
                    </div>
                </Item>
                <Item
                    small
                    url="/static/images/signs-fall.png"
                >
                    <div
                        dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'signs.fall'})}}
                    />
                </Item>
            </div>
        </div>

        <div className="signs__items--mobile">
            <Item
                small
                url="/static/images/signs-fall.png"
            >
                <div
                    dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'signs.fall'})}}
                />
            </Item>
            <Item
                url="/static/images/signs-milk.png"
            >
                <div>
                    <FormattedMessage id="signs.milk" />
                </div>
            </Item>
            <Item
                url="/static/images/signs-age.png"
                >
                <div
                    dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'signs.age'})}}
                />
            </Item>
            <Item
                small
                url="/static/images/signs-cast.png"
                >
                <div
                    dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'signs.cast'})}}
                />
            </Item>
            <Item
                url="/static/images/signs-sofa.png"
            >
                <div
                    dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'signs.sofa'})}}
                />
            </Item>
            <Item
                url="/static/images/signs-broken.png"
            >
                <div>
                    <FormattedMessage id="signs.broken" />
                </div>
            </Item>
            <Item
                phantom
                >
            </Item>
            <Item
                bottom
                url="/static/images/signs-smoke.png"
                >
                <div>
                    <FormattedMessage id="signs.smoke" />
                </div>
            </Item>
            <Item
                large
                url="/static/images/signs-smile.png"
                >
            </Item>
        </div>
    </div>
));
