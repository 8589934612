import React from 'react';
import HeaderText from "./HeaderText";
import Item from "./Item";
import './index.css';
import {FormattedMessage} from "react-intl";

export default () => (
    <div className="increased-need">
        <HeaderText/>
        <div className="increased-need-items">
            <Item
                logo="/static/svg/increased-need-salt.svg"
                text ={<FormattedMessage id="increased.need.salt" />}
            >     
            </Item>
            <Item
                logo="/static/svg/increased-need-alcohol.svg"
                text={<FormattedMessage id="increased.need.alcohol" />}
            >     
            </Item>
            <Item
                logo="/static/svg/increased-need-smoking.svg"
                text={<FormattedMessage id="increased.need.smoking" />}
            >   
            </Item>
            <Item
                logo="/static/svg/increased-need-soda.svg"
                text={<FormattedMessage id="increased.need.soda" />}
            >    
            </Item>
            <Item
                logo="/static/svg/increased-need-coffee.svg"
                text={<FormattedMessage id="increased.need.coffee" />}
            >    
            </Item>
        </div>
    </div>
);